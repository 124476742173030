 const Success = () => {

	 return (
		 <h1>Success</h1>

	 )


 }

export default Success;
